import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import { Container, Row, Form, Button } from "react-bootstrap";
import Title from "../components/Title";
import Seo from "../components/seo";

const Contact = ({ location, path }) => {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };
  return (
    <Layout location={location} path={path}>
      <Seo title="Contact" />
      <Row className="pt-5">
        <Title title="Contact" position="center" pageTitle />
      </Row>
      <Container>
        <Row className="my-5 p-sm-5">
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
            action="https://formspree.io/f/mknebwej"
            method="POST"
          >
            <Form.Group className="mb-3" controlId="formFirstName">
              <Form.Control
                type="text"
                placeholder="First name"
                name="firstname"
                required
              />
              <Form.Control.Feedback type="invalid">
                This field is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formSurname">
              <Form.Control
                type="text"
                placeholder="Surname"
                name="surname"
                required
              />
              <Form.Control.Feedback type="invalid">
                This field is required.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPhoneNumber">
              <Form.Control
                type="text"
                placeholder="Phone number"
                name="phonenumber"
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="formMessage">
              <Form.Control
                as="textarea"
                placeholder="Your message"
                name="message"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please leave a message.
              </Form.Control.Feedback>
            </Form.Group>
            <StyledButton type="submit">Submit</StyledButton>
          </Form>
        </Row>
      </Container>
    </Layout>
  );
};

const StyledButton = styled.button`
  border: none;
  padding: 15px 40px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
  background-size: 300% 100%;
  width: 100%;

  border-radius: 20px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;

  &:hover {
    color: white;
    background-position: 100% 0;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }

  &:focus {
    outline: none;
  }

  background-image: linear-gradient(
    to right,
    #f5ce62,
    #e43603,
    #fa7199,
    #e85a19
  );
  box-shadow: 0 4px 15px 0 rgba(229, 66, 10, 0.75);
`;

export default Contact;
